import { Agent } from "@src/lib/interface/src";
import { useSelector } from "react-redux";

import { Session, SessionEnv, Store } from "../session";

/**
 * Helper hook to access the global session state.
 */
export function useSession(): Session {
  return useSelector((store: Store) => store.session);
}

/**
 * Helper hook to access global session.state.agent || {}
 */
export function useDefinedAgent(): Agent {
  const { agent = {} } = useSession();
  return agent;
}

/**
 * Helper hook to access global session.state.userId || ""
 */
export function useDefinedUserId(): string {
  const { userId = "" } = useSession();
  return userId;
}

/**
 * Helper hook to access global session.state.env || {} as SessionEnv
 */
export function useDefinedEnv(): SessionEnv {
  const { env } = useSession();
  return env ?? ({} as SessionEnv);
}
